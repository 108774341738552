@import './rtl';
@import './webchat';
@import './carousel';

$lilac-color: #7f81ae;

:root {
  --background: #eae3d9 !important;
  --bs-primary: #5864a6;
  --bs-primary-hover: #3b4372;
  --va-header-color: #113059;
  --va-text-color: #181c2a;
  --va-icon-color: #181c2a;
  --va-invalid-form-color: #ff2700;
  --va-mention-dynamic: #28a745;
  --va-mention-static: #113059;
  --va-mention-intentEntity: #ee6f42;
  --va-mention-projectEntity: #7f81ae;
  --va-mention-custom: #eae3d9;
  --va-mention-binaryBase64: #dd6767;
  --va-mention-undefined: rgba(0, 0, 0, 0.3);
  --va-input-border-color: #dfe0e6;
}

@import '../fonts/nunito-sans/nunito-sans';

body,
body .h1,
body .h2,
body .h3,
body .h4,
body .h5,
body .h6,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6,
html,
a,
.btn,
.ant-pagination-item,
.ngx-datatable,
.dropdown,
.ngx-datatable,
.breadcrumb,
.ant-tabs {
  font-family: 'Nunito Sans' !important;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  cursor: pointer;
}

::-webkit-scrollbar {
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--background);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--bs-primary);
}

body {
  font-size: 13px !important;
  background-image: none !important;
  background-color: #f9fafb !important;
}

abp-loader-bar .abp-progress {
  background-color: var(--bs-primary) !important;
}

a {
  text-decoration: none;
  color: #1890ff;
}

i {
  &.bi,
  &.fa,
  &.fas,
  &.far,
  &.fa-solid,
  &.fa-regular,
  &.fa-light,
  &.fal,
  &.fab {
    color: var(--bs-primary);
  }
}

.form-group {
  margin-bottom: 1rem;
}

div[validationtarget].form-group {
  input.input-validation-error {
    border-inline-end: none;
  }

  input.input-validation-error + button {
    border-top: 1px solid #d12941;
    border-inline-end: 1px solid #d12941;
    border-end-end-radius: 0;
  }
}

div[validationtarget].form-group + cai-validation-error {
  .field-validation-error.text-danger span {
    margin-top: -1rem;
  }
}

.entry-row + div,
.content-load {
  animation-duration: 0.5s;
  animation-name: slide-up-fade-in;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .lp-topmenu {
    padding-top: 120px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1366px) {
  .lp-topmenu {
    padding-top: 65px !important;
  }
}

@media only screen and (min-width: 992px) and (min-width: 1200px) {
  .lp-topmenu .lp-sidebar .lp-sidebar-wrapper {
    margin: -51px auto 0 !important;

    nav {
      text-align: left !important;
      padding-left: 275px !important;
    }
  }
}

.lp-topmenu .lp-sidebar .lp-sidebar-wrapper nav ul,
.lp-opened-sidebar .lp-sidebar .lp-sidebar-navi ul {
  li {
    a {
      color: var(--va-text-color);

      span.lp-icon {
        opacity: 1;

        i {
          color: var(--va-icon-color) !important;
        }
      }

      .lp-text {
        opacity: 1;
      }
    }

    &:has(ul li.current) > a,
    &.current > a,
    &:hover > a,
    & a:hover {
      background: #eff0f7 !important;
      color: var(--bs-primary);

      .lp-icon i {
        color: var(--bs-primary) !important;
      }
    }
  }
}

.lp-topmenu .lp-sidebar .lp-sidebar-wrapper nav ul {
  li > ul > div {
    border-radius: 6px;
    border: 1px solid rgba(127, 129, 174, 0.24);
    background: #fff;
    box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.12);

    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
  }

  li {
    &:first-of-type > a {
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
    }

    &:last-of-type > a {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
}

.navbar-toggler {
  i {
    color: var(--bs-primary) !important;
  }
}

body.lp-topmenu header .toolbar-nav .btn i {
  color: var(--bs-primary);
}

.entry-row .btn-group {
  @media only screen and (max-width: 991px) {
    white-space: nowrap;
    display: flex;
  }
}

@keyframes slide-up-fade-in {
  0% {
    opacity: 0.01;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

.form-control {
  font-size: 12px;
  background-color: white !important;
  border: 1px solid var(--va-input-border-color);
  border-radius: 3px;

  &.input-validation-error {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  &:active,
  &:focus,
  &:visited {
    border-color: transparent !important;
    box-shadow: 0 0 0.2rem #113059 !important;
  }
}

.fs-16 {
  font-size: 16px;
}

.fs-12 {
  font-size: 12px;
}

.c-pointer {
  cursor: pointer;
}

.c-move {
  cursor: move;
}

.row.entry-row {
  margin: 8px 0px;
  align-items: center;

  [class^='col'] {
    padding: 0;
  }
}

.card .card-body {
  padding: 15px;
}

.confirmation-button--approve {
  border-color: var(--bs-primary) !important;
  border-width: 2px !important;
  border-style: solid !important;

  &:hover {
    background-color: var(--bs-primary-hover) !important;
    border-color: var(--bs-primary-hover) !important;
  }
}

.confirmation-button--reject {
  background-color: white !important;
  color: var(--bs-primary) !important;
  border-color: var(--bs-primary) !important;
  border-width: 2px !important;
  border-style: solid !important;

  &:hover {
    background-color: var(--bs-gray-300) !important;
    color: var(--bs-primary) !important;
  }
}

.confirmation .confirmation-dialog .icon-container.warning .icon {
  color: #d12941 !important;
}

.btn {
  &.btn-xs {
    padding: 2px 8px !important;
    font-size: 10px;
  }

  &[disabled] {
    &:hover {
      background-color: var(--bs-secondary) !important;
      color: white;
      border-color: var(--bs-primary) !important;
    }
  }

  &-primary {
    background-color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;

    &:hover,
    &:active {
      background-color: var(--bs-primary-hover) !important;
      border-color: var(--bs-primary-hover) !important;
    }

    i {
      color: #fff !important;
    }
  }

  &-outline-primary {
    color: var(--bs-primary) !important;
    border-color: var(--bs-primary) !important;

    &:hover,
    &:active,
    &:focus {
      background-color: #eef4fc !important;
      color: var(--bs-primary) !important;
    }
  }

  &-outline-primary[disabled] {
    background-color: var(--bs-gray-300) !important;
    color: var(--bs-primary) !important;

    &:hover {
      background-color: var(--bs-secondary) !important;
      color: var(--bs-secondary);
    }
  }

  &-warning[disabled] {
    &:hover {
      background-color: var(--bs-warning) !important;
      color: white;
      border-color: var(--bs-warning) !important;
    }
  }

  &-secondary[disabled] {
    &:hover {
      background-color: var(--bs-secondary) !important;
      color: white;
      border-color: var(--bs-secondary) !important;
    }
  }

  &-warning {
    i {
      color: white;
    }
  }
}

.ng-select {
  &.ng-select-focused:not(.ng-select-focus-disabled) > .ng-select-container {
    border-color: transparent !important;
    box-shadow: 0 0 0.2rem #113059 !important;
  }

  &.ng-select-focus-disabled > .ng-select-container {
    border-color: transparent !important;
    box-shadow: none !important;
  }

  &.ng-select-multiple {
    .ng-value {
      background: var(--bs-primary) !important;
      color: white !important;

      &-icon,
      &-label {
        &:hover {
          background: var(--bs-primary) !important;
        }
      }
    }
  }

  &.ng-select-single .ng-select-container {
    height: 30px !important;
  }

  .ng-select-container {
    border: 1px solid var(--va-input-border-color);
    border-radius: 3px;
    min-height: 30px !important;
    box-sizing: border-box;

    &:focus {
      background-color: #fff !important;
    }
  }

  &.designer-select {
    .ng-select-container {
      min-height: 31.6px !important;
    }
  }

  &.input-validation-error {
    border: 1px solid;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;

    .ng-select-container {
      border-color: transparent;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
}

// this class created for diagram form about no-validate
.input-validation-error-node-validate {
  border: 1px solid var(--va-invalid-form-color) !important;
  border-radius: 0.25rem;

  &:focus {
    box-shadow: none !important;
    border-color: var(--va-invalid-form-color) !important;
  }

  .ng-select-container {
    border-color: transparent;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  &:is(input).form-control.is-invalid {
    padding: 0.375rem 0.75rem;
  }
}

.ng-value-container {
  max-height: 80px;
  margin-right: 10px;
  overflow-y: auto;
}

// =================================================================

//  data table, dropdown and remove all button style
.ngx-datatable,
.ngx-datatable.material {
  background: transparent !important;

  .datatable-header {
    height: 35px !important;

    .datatable-header-cell {
      color: #113059;
      font-weight: 600;
    }

    .datatable-header-inner {
      &:last-child {
        .datatable-row-right,
        .datatable-row-left {
          background-image: none !important;
        }
      }

      .datatable-header-cell {
        font-size: 13px;
        background: transparent !important;
        line-height: 2 !important;
        padding: 5px 5px 5px 10px !important;
      }
    }
  }

  .datatable-footer {
    .datatable-pager li.active a {
      background-color: var(--bs-primary);
    }
  }

  &.bordered {
    .datatable-body-row {
      border-top: 1px solid #f8f8f8 !important;
    }
  }

  .datatable-body {
    &-cell {
      padding: 2px 5px 2px 10px !important;
      font-size: 13px !important;
    }
  }
}

.data-tables-remove-all {
  position: absolute;
  inset-block-start: 10px;
  inset-inline-end: 5px;
  z-index: 1;
}

.data-table-actions {
  text-align: right !important;
}

.datatable-checkbox input[type='checkbox']:checked:before {
  border-color: var(--bs-primary);
}

.datatable-checkbox {
  margin-block-start: 2px;
}

.dropdown-none-caret {
  .dropdown-toggle {
    &:after {
      border: 0;
    }
  }
}

// =================================================================

.input-group {
  & > *:first-child:is(ng-select) {
    .ng-select-container {
      border-start-end-radius: 0;
      border-end-end-radius: 0;
    }

    &.ng-select-opened.ng-select-bottom {
      .ng-select-container {
        border-end-start-radius: 3px;
      }
    }

    &.ng-select-opened.ng-select-top {
      .ng-select-container {
        border-start-start-radius: 3px;
      }
    }
  }

  & > *:last-child:is(ng-select) {
    .ng-select-container {
      border-start-start-radius: 0;
      border-end-start-radius: 0;
    }

    &.ng-select-opened.ng-select-bottom {
      .ng-select-container {
        border-end-end-radius: 3px;
      }
    }

    &.ng-select-opened.ng-select-top {
      .ng-select-container {
        border-start-end-radius: 3px;
      }
    }
  }
}

.input-group cai-validation-error {
  width: 100%;
  margin-left: 32px;
}

.modal .input-group cai-validation-error {
  width: 100%;
  margin-left: 35px;
}

.ant-tabs {
  font-size: inherit !important;

  .ant-tabs-tab {
    font-size: 13px;
  }

  .ant-tabs-content {
    height: 100%;
  }

  .ant-tabs-nav .ant-tabs-nav-more {
    padding: 8px !important;
  }
}

body.lp-topmenu .lp-content {
  padding: 0 24px !important;
}

abp-footer,
.lp-content .lp-footer,
#abp-modal-close-button,
.flag-icon {
  display: none;
}

.ant-switch.ant-switch-checked {
  background: var(--bs-primary);
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 6px 16px 6px 10px !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  top: 0 !important;
}

// chip tag component

.multi-tag {
  border: 1px solid var(--va-input-border-color) !important;
  border-radius: 3px;

  .tag__text,
  .ng2-tag-input__text-input {
    font-size: 12px;
  }

  // for tags input
  .ng2-tag-input {
    padding: 0 !important;
    border: 0 !important;
    min-height: 30px !important;

    .ng2-tags-container {
      padding-left: 1px;
    }
  }

  .ng2-tag-input__text-input {
    background-color: white !important;
    height: 30px !important;
    font-size: 12px !important;
    width: 19rem;
  }

  tag {
    height: 30px !important;
    line-height: 26px !important;
  }
}

delete-icon svg {
  height: 30px !important;
}

// END OF THE TAGS INPUT

// for  go js
.gsfBackground {
  border-color: #b8fa9e;
  font-size: 11px;
  outline: none;
  margin-bottom: 14px;
}

canvas {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.multiple-tag {
  .multiple-tag-icon {
    position: absolute;
    font-size: 12px;
    inset-block-start: 7px;
    inset-inline-end: 7px;
    z-index: 1001;
    padding: 4px;
    border-radius: 2px;
    margin-block-start: -2px;

    &:hover {
      font-weight: bold;
    }
  }
}

// panel
.saved-notification {
  position: absolute;
  inset-block-start: 4px;
  font-size: 12px;
  z-index: 1001;
  background-color: #51a351;

  &.position-top {
    inset-block-start: 18px;
  }

  i {
    color: #fff;
  }
}

.saving-notification {
  position: absolute;
  inset-block-start: 4px;
  font-size: 12px;
  z-index: 1001;
  background-color: #f2f2f2;
  color: #808080;

  &.position-top {
    inset-block-start: 18px;
  }

  i {
    color: #808080;
  }
}

.ant-tabs-nav-add {
  display: flex;
  justify-content: center;
  align-items: center;

  .svg-plus {
    stroke: #666;
  }

  &:hover {
    .svg-plus {
      stroke: #333;
    }
  }
}

.code-generate {
  overflow-y: auto;
}

.code-generate-json {
  height: calc(100vh - 200px);
}

.action-flow-outer-panel .palette-div {
  height: calc(100vh - 302px) !important;
}

.action-flow-outer-panel .diagram-div {
  height: calc(100vh - 246px) !important;
}

.action-flow-outer-panel .test-button {
  height: calc(100vh - 246px) !important;
}

.action-flow-outer-panel .message-content:not(.anything) {
  height: calc(100vh - 381px) !important;
}

// Do not delete following style since it is used in localization files.
.confirmationDetail {
  font-weight: bold;
  display: inline-block;
  max-width: 20rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  cursor: default;
}

.text-editor-mode {
  background-color: black !important;
  color: var(--bs-warning) !important;
  border: 0;
}

.home-page {
  .box {
    &:hover {
      background: var(--background) !important;
    }

    .icon {
      color: black;

      i {
        font-size: 36px;
      }
    }
  }
}

.inner p {
  margin: 0 !important;
}

.custom-input {
  background-color: #fff !important;

  &:focus {
    box-shadow: none !important;
  }
}

.ngx-json-viewer {
  overflow: auto !important;
}

.chat-flow {
  width: 40%;
  z-index: 10;
  position: absolute;
  background: white;
  padding: 8px 10px 10px 10px;

  &.project-position {
    inset-block-start: 1px;
    inset-inline-end: 1px;
  }

  &.chat-position {
    inset-block-start: 1px;
    inset-inline-end: 1px;
  }
}

.train-in-progress,
.chat-validation-icon {
  color: #ee6f42 !important;
}

.node-form-invalid-design-info {
  color: var(--va-invalid-form-color) !important;
}

.validation-icon .tooltip-inner {
  max-width: 550px;
  width: 550px;
  white-space: break-spaces;
}

.wider-tooltip-container .tooltip-inner {
  max-width: 700px !important;
  white-space: break-spaces;
}

.test-button {
  width: 40px;
  height: calc(100vh - 246px);
  background-color: #f2f2f2;

  i {
    position: relative;
    z-index: 19;
  }
}

.information-icon {
  margin-left: 5px;
}

// for responsive
.search-inputs {
  @media only screen and (max-width: 991px) {
    width: 100% !important;
  }
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-2px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(2px, 0, 0);
  }
}

.add-responses {
  width: 9.5rem;
}

.add-responses .ng-placeholder::before {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900;
  content: '\f067';
}

.shake {
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
}

.border-dash {
  border: 1px dashed var(--bs-primary);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: var(--bs-primary) !important;
}

.ant-radio-button-wrapper:hover {
  position: relative;
  color: var(--bs-primary);
}

.modal-dialog-scrollable .modal-body,
.modal-dialog-scrollable .modal-content {
  overflow: visible;
}

.modal .modal-content {
  .modal-header > h3 {
    margin-bottom: 0;
  }
}

.is-invalid {
  .ng-select-container {
    border-radius: 4px !important;
  }
}

.entity-table-container {
  .ngx-datatable .datatable-header .datatable-header-inner .datatable-header-cell,
  .ngx-datatable.material .datatable-header .datatable-header-inner .datatable-header-cell,
  .ngx-datatable .datatable-body-cell,
  .ngx-datatable.material .datatable-body-cell {
    padding: 5px 5px 5px 0px !important;
  }
}

.datatable-checkbox input[type='checkbox'] {
  margin: 0;
}

.disabled-chat-open {
  position: absolute;
  inset-block-start: 2px;
  inset-block-end: 0;
  inset-inline-end: 2px;
  width: 32px;
  height: 32px;
  z-index: 100;
  background: rgb(242, 242, 242, 0.6);
}

.quick-reply-button-title {
  border: 2px solid transparent;

  &:focus {
    outline: 0;
    border: 2px solid #eae3d9;
    box-shadow: 0 0 0.1rem var(--bs-secondary);
    border-radius: 0.25rem;
    background-color: #fcfcfc;
    background-clip: padding-box;
  }
}

.tooltip {
  font-family: 'Nunito Sans' !important;
  opacity: 1 !important;

  .tooltip-inner {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  &.bs-tooltip {
    &-end {
      .tooltip-arrow:before {
        border-right-color: white !important;
      }
    }

    &-start {
      .tooltip-arrow:before {
        border-left-color: white !important;
      }
    }

    &-top {
      .tooltip-arrow:before {
        border-top-color: white !important;
      }
    }

    &-bottom {
      .tooltip-arrow:before {
        border-bottom-color: white !important;
      }
    }
  }
}

.auto-dropdown {
  z-index: 0 !important;
}

.high-dropdown {
  z-index: 1055 !important;
}

input[type='search']::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

input[type='search'] {
  -webkit-appearance: none;
}

.w-5 {
  width: 5% !important;
}

.w-35 {
  width: 35% !important;
}

.w-60 {
  width: 60% !important;
}

@media (min-width: 576px) {
  .modal-xl {
    max-width: calc(100% - 2rem);
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

@media (min-width: 576px) {
  .modal-xxl {
    max-width: calc(100% - 2rem);
  }
}

@media (min-width: 1717px) {
  .modal-xxl {
    max-width: 1685px;
  }
}

.white-background {
  background-color: white !important;
}

.ng-dropdown-panel {
  z-index: 1056 !important;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
  max-height: 11rem !important;
}
.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  font-weight: bold;
  color: black;
}
.page-count {
  padding: 0 10px !important;
}

nz-tab-nav-operation {
  overflow: visible;
}

.channel-form {
  position: relative;

  .ant-tabs-small > .ant-tabs-nav .ant-tabs-tab {
    font-size: 12px !important;
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin-left: 10px;
  }

  .ant-tabs-tab.ant-tabs-tab-disabled {
    margin-left: 6px;
    cursor: inherit;
  }
}

.ant-divider-horizontal {
  margin: 12px 0 !important;
}

.btn-separator {
  padding-left: 0px !important;
  padding-right: 0px !important;
  background-color: white !important;
  z-index: 99 !important;
  border-width: 0px !important;
  width: 1px !important;
}

.widget-container {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.widget-container .card {
  border-radius: 6px;
  border: 1px solid rgba(127, 129, 174, 0.24);
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.12);
}

.card cai-filter-panel {
  box-shadow: 2px 2px 2px rgb(0 0 0 / 15%);
}

.widget-container .card-container {
  //height: 100%;
}

div.ngb-tp {
  justify-content: center !important;
}

.dashboard-widget-no-data {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-weight: bold;
  opacity: 0.7;
  color: Silver !important;
  width: 100%;
  height: 100%;
}

sestek-view > .entry-row,
abp-page > .entry-row {
  div:first-child > .content-header-title {
    display: none;
  }
}

cai-project-list abp-page .entry-row,
cai-conversation abp-page .entry-row,
cai-response-management-main abp-page .entry-row,
cai-channel-integration-list abp-page .entry-row,
cai-service-integration-list abp-page .entry-row,
cai-dashboard-main abp-page .entry-row {
  div:first-child > .content-header-title {
    display: block;
  }
}

.breadcrumb {
  flex-wrap: nowrap !important;
  padding: 5px 0 0;

  a {
    white-space: nowrap;
  }

  li {
    white-space: nowrap;
    display: flex;
  }

  .breadcrumb-item:first-child {
    display: none;
  }

  .breadcrumb-item:nth-child(2):before {
    content: '';
    padding: 0;
  }

  .breadcrumb-item {
    align-items: center;
    opacity: 1;
    margin-top: 4px;
    color: var(--va-text-color);
    font-size: 12px;
    line-height: normal;
    cursor: default;

    a {
      color: var(--va-text-color);
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.confirmation-dialog .content .title {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.confirmation-dialog .content .message {
  span b {
    display: inline-block;
    max-width: 20rem;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: top;
    text-overflow: ellipsis;
    cursor: default;
  }
  word-break: break-word;
  float: none;
}

abp-button:has(button[disabled]) {
  pointer-events: none;
  cursor: default;
}

.open-wrapper {
  width: 100%;

  .wrapper-content {
    float: left;
    width: calc(100% - 250px);
  }
  &.open {
    .wrapper-content {
      width: calc(100% - 60px);
    }
  }

  .wrapper-content {
    border-radius: 4px;
    padding: 0 10px;
  }
}

.active-link {
  border: solid 1px var(--bs-primary);
}

.filter-panel-accordion {
  height: 100%;

  .accordion-body {
    padding: 8px 8px;
  }
}

.dropdown-menu {
  border-radius: 6px;
  border: 1px solid rgba(127, 129, 174, 0.24);
  background: #fff;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.12);
  padding: 0;

  .dropdown-item {
    &,
    &:hover,
    &:active,
    &:focus {
      opacity: 1;
      color: var(--bs-primary);

      i {
        color: var(--bs-primary) !important;
      }
    }

    &:hover {
      background-color: #eef4fc;
    }
  }

  .dropdown-divider {
    margin: 0;
  }
}

.dropdown-menu-sm {
  min-width: auto !important;
}

.text-muted-drop-down-item {
  opacity: 0.4 !important;
}

.confirmation .confirmation-dialog {
  background: white !important;
}

.confirmation .confirmation-dialog .footer {
  background: white !important;
}

.confirmation .confirmation-dialog .content .title {
  font-size: 18px !important;
}

.confirmation .confirmation-dialog .footer {
  justify-content: center !important;
}

.confirmation .confirmation-dialog .footer .confirmation-button {
  font-size: 1em !important;
  line-height: 0.8;
  margin: 0 2px !important;
  background-color: var(--bs-primary);
}

.confirmation .confirmation-backdrop {
  background: rgba(0, 0, 0, 0.2) !important;
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.2) !important;
}

.confirmation-button--approve {
  padding: 10px 28px !important;
}

.agent-field {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));

  .selected-prebuilt {
    inset-block-start: 2px;
    inset-inline-end: 2px;

    .fa {
      font-size: 16px;
    }
  }

  &-card {
    height: 80px;
    border-color: $lilac-color !important;
    color: $lilac-color;
    font-weight: 500;
    &:hover {
      filter: brightness(0.9);
    }

    &-name {
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &-delete-button {
      inset-block-start: 0;
      inset-inline-end: 0;
      padding-block-start: 0.25rem !important;
    }
  }
}

.bg-lilac {
  background-color: $lilac-color !important;
}

div.block-ui-wrapper {
  background-color: var(--bs-gray-100);
  opacity: 0.7;
  z-index: unset;
}

.response-filters-wrapper .block-ui-wrapper {
  z-index: 1100 !important;
}

.test-call-wrapper .block-ui-wrapper {
  z-index: 1100 !important;
  height: calc(100% - 200px);
}

div.block-ui-wrapper .loader {
  border-color: gray; /* Circle color */
  border-left-color: white; /* Spinning section in circle color */
  margin-top: 13px;
  font-size: 4px;
  width: 8em;
  height: 8em;
}

.flow-va-navigator-wrapper .ant-tabs-bottom > .ant-tabs-nav,
.flow-va-navigator-wrapper .ant-tabs-bottom > div > .ant-tabs-nav,
.flow-va-navigator-wrapper .ant-tabs-top > .ant-tabs-nav,
.flow-va-navigator-wrapper .ant-tabs-top > div > .ant-tabs-nav {
  margin: 0 0 0px !important;
}

.flow-va-navigator-wrapper .ant-tabs-nav-operations {
  display: none !important;
}

.flow-va-navigator-wrapper .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
.flow-va-navigator-wrapper .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
  padding: 0px;
}

.mg-left-140px {
  margin-left: 140px;
}

.color-1c3d7c {
  color: #1c3d7c !important;
}

/* Message */
div.block-ui-spinner .message {
  color: gray; /* Loading text color */
}

cai-root > abp-dynamic-layout > abp-application-layout > div.h-100 {
  max-height: calc(100vh - 105px);
  overflow-y: scroll;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  cai-root > abp-dynamic-layout > abp-application-layout > div.h-100 {
    max-height: calc(100vh - 160px);
  }
}

@media only screen and (min-width: 1366px) {
  cai-root > abp-dynamic-layout > abp-application-layout > div.h-100 {
    max-height: calc(100vh - 103px);
  }
}

.no-resize {
  resize: none;
}

.file-select .ng-select .ng-arrow-wrapper,
.attachment-select .ng-select .ng-arrow-wrapper {
  visibility: hidden;
}

.file-select i.select-icon,
.attachment-select i.select-icon {
  position: absolute;
  right: 10px;
  top: 10px;
}

.disabled-rich-text {
  div .cai-rich-text-editor {
    background-color: #f2f2f2 !important;
  }

  .add-mention-icon {
    pointer-events: none !important;
  }
}

.drag-drop-zone {
  background-color: #f8f9ff !important;
  border: dashed #5965a6 3px !important;
}

.drag-drop-zone * {
  pointer-events: none;
}

.initial-drag-drop-zone {
  border: solid 1px #a8afd1;
  border-radius: 6px;
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.12);
}

.disabled-item {
  color: gray !important;
  font-weight: 500;
}

.train-button {
  .pulse {
    border-radius: 50%;
    transform: scale(1);
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
    animation:
      pulseTransform 1s infinite,
      pulseBoxShadow 2s infinite;
  }
  &:hover .pulse,
  &.hover .pulse {
    animation: none;
  }
}

@keyframes pulseTransform {
  0% {
    transform: scale(1);
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes pulseBoxShadow {
  0% {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
  60% {
    background-color: rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 9px rgba(255, 255, 255, 0);
  }
  100% {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  }
}

.abp-toast {
  i:not(.fa-times) {
    font-size: 30px !important;
  }

  i.fa-times {
    font-size: large;
  }

  &.abp-toast-success {
    border: 2px solid #547c4a !important;
    background-color: #e8f0e6 !important;
    color: #547c4a !important;

    i {
      color: #547c4a;
    }
  }

  &.abp-toast-error {
    border: 2px solid #c24a4a !important;
    background-color: #f5e0e0 !important;
    color: #c24a4a !important;

    i {
      color: #c24a4a;
    }
  }

  &.abp-toast-warning {
    border: 2px solid #dc6c3a !important;
    background-color: #fde5d8 !important;
    color: #dc6c3a !important;

    i {
      color: #dc6c3a;
    }
  }
}

.filter-container {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100%;

  .filter-form {
    display: flex;
    flex-direction: column;
    height: 100%;

    .navbar {
      border-radius: 6px 6px 0px 0px;
      background: var(--bg-gray, #f2f2f2);
      padding: 19px 0;

      .title {
        color: #181c2a;
        font-size: 17px;

        .filter-icon {
          border: 1px solid #181c2a;
          border-radius: 50%;
          width: 20px;
          height: 20px;
        }
      }
    }

    .form-control {
      color: var(--va-text-color) !important;
    }

    .filter-panel-accordion {
      background-color: white;
      ngb-accordion {
        .accordion-item {
          border-radius: 0;
          border-color: rgba(#7f81ae, 0.21);
          border-right: 0;
          border-left: 0;

          margin: 0 10px;
          padding-bottom: 5px;

          i.fa-search {
            color: #9b9c9e;
          }

          input::placeholder {
            color: #9b9c9e;
          }

          &:first-of-type {
            border-top: 0;
            margin-top: 5px;
          }

          &:last-of-type {
            border-bottom: 0;
          }
        }
      }

      .input-group {
        border-radius: 3px;
        border: 1px solid var(--va-input-border-color);

        .input-group-search {
          align-self: center;
          border-radius: 3px;
        }

        input {
          border: 0;
        }
      }

      .ng-select {
        &.ng-select-focused:not(.ng-select-focus-disabled) > .ng-select-container {
          border: 1px solid var(--va-input-border-color) !important;
          box-shadow: none !important;
        }

        .ng-select-container {
          color: var(--va-text-color);

          .ng-value-container .ng-input > input {
            color: var(--va-text-color);
          }

          .ng-clear-wrapper {
            .ng-clear {
              color: #6f6f6f;
            }
          }

          .ng-arrow-wrapper {
            .ng-arrow {
              color: #6f6f6f;
            }
          }
        }

        .ng-dropdown-panel {
          border: 0;
          border-radius: 0px 0px 6px 6px;
          box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.12);
          margin-top: 0;
          margin-bottom: 0;

          .ng-dropdown-panel-items {
            border-radius: 0px 0px 6px 6px;

            .ng-option {
              color: var(--va-text-color);
            }
          }
        }

        &.customer-information-select {
          width: 30%;

          .ng-select-container {
            border-color: var(--va-input-border-color) !important;
            border-width: 0 1px 0 0 !important;
          }

          .ng-dropdown-panel {
            margin-top: 1px;
            margin-left: -1px;
          }

          .ng-clear-wrapper .ng-clear {
            display: none !important;
          }
        }
      }

      .filter-panel-items {
        .filter-panel-item {
          background-color: #fff;
          border-bottom: 1px solid rgba(127, 129, 174, 0.21);
          border-radius: 0;
          margin: 0 10px;
          padding-bottom: 5px;

          &:first-of-type {
            margin-top: 5px;
          }

          &:last-of-type {
            border-bottom: 0;
          }

          .filter-panel-item-header {
            margin-bottom: 0;

            .filter-panel-item-title {
              white-space: nowrap;
              font-size: 14px;
              font-weight: 600px;
              padding: 8px 0px 0px 8px;
              color: var(--va-header-color);
              margin-bottom: 0;
            }
          }

          .filter-panel-item-body {
            padding: 8px 8px;
          }
        }
      }
    }

    .filter-action-buttons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 16px;
      margin: 0 15px;

      .clear-button {
        border: 1px solid #7f81ae;
        color: var(--violet-color, #7f81ae);
        font-weight: 600;

        &:hover {
          border: 1px solid var(--violet-hover-color, #5f6295);
          background-color: var(--hover-color, #ededf1);
        }
      }

      .submit-button {
        color: #fff;
        background-color: var(--bs-primary);
        border-color: var(--bs-primary);
        font-weight: 600;

        i {
          color: #fff;
        }

        &:hover {
          background-color: var(--bs-primary-hover) !important;
        }
      }
    }
  }
}

.language-tabs-container .ant-tabs-content-holder {
  overflow: visible !important;
  position: relative !important;
  z-index: 1 !important;
}

.language-tabs-container .ant-tabs {
  overflow: visible !important;
}

.replace-panel-popover {
  border: 1px solid var(--va-input-border-color);
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.12);
  z-index: 970;
  max-width: 100% !important;
}

.search-results .cai-rich-text-editor {
  border: 0;
}

.btn-xsm {
  padding: 1px 6px;
  font-size: 0.8em;
  width: 100px;
}

.label-sm {
  font-size: 0.9em;
  align-content: space-evenly;
}

.btn {
  border-radius: 3px;
}

.bg-primary {
  background-color: var(--bs-primary) !important;
}

.cdk-overlay-container {
  z-index: 999999;

  .ant-select-not-found {
    padding: 0 10px;
  }
}

.bi.bi-person-workspace {
  font-family: 'Font Awesome 6 Pro';
  padding: 0 12px;
}

// Account => Security Logs => Refresh Button & Audit Log Refresh Button
div.mt-3.mb-lg-0.d-grid {
  padding-top: 11px;
}

// Entity Changes Refresh Button
div.col-6.d-grid.text-end {
  padding-top: 11px;
}

#SettingManagementWrapper {
  .abp-md-form {
    max-width: 100%;
  }
}
